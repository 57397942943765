import { GetServerSideProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { ReactElement } from 'react'

import { HeaderLayout } from '@/components/layouts'
import { MarketOnSale, MarketRanking } from '@/components/market'

function MarketHome() {
  return (
    <main>
      <MarketRanking.WithErrorBoundary />
      <MarketOnSale.WithErrorBoundary />
    </main>
  )
}

export default MarketHome

MarketHome.getLayout = function getLayout(page: ReactElement) {
  return (
    <HeaderLayout>
      <main>{page}</main>
    </HeaderLayout>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const localeValue = locale ?? 'ko'

  return {
    props: {
      ...(await serverSideTranslations(localeValue, [
        'common',
        'web-header',
        'web-market-home',
        'web-modal',
      ])),
    },
  }
}
